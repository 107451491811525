<template>
   <div>
        <div class="slider-fertil">
            <swiper :pagination="true" class="mySwiper">
                <swiper-slide>
                    <img src="../assets/slider.png" alt="slider">
                    <div class="content-slider">
                        <h2>SIMATAUCCA</h2>
                        <p>lorem ipsun</p>
                        <button class="btn-slider">
                            Ver Más
                        </button>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/slider1.jpg" alt="slider">
                </swiper-slide>
                <swiper-slide>
                    <img src="../assets/slider2.jpg" alt="slider">
                </swiper-slide>
            </swiper>
        </div>
   </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper.min.css'
    import 'swiper/swiper-bundle.css';

    import SwiperCore, {
    Pagination
    } from 'swiper';

    SwiperCore.use([Pagination]);

    export default {
        name: 'SliderComponent',
        components: {
            Swiper,
            SwiperSlide,
        },
    }
</script>

<style scoped>
.content-slider{
    position: absolute;
    padding: 25px;
    width: 400px;
    display: grid;
    justify-content: left;
    background-color: transparent;
    text-align: start;
    color: white;
}
.content-slider h2{
    font-size: 45px;
}
.content-slider button{
    color: black;
    padding: 5px 15px;
    background-color: white;
    border-radius: 15px;
    width: max-content;
    border:none;
    font-size: 15px;
}
.slider-fertil{
    width: 100%;
    height: 650px;
}
.swiper {
  width: 100%;
  height: 100%;
}
.mySwiper{
    height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>