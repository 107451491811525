<template>
    <div class="members-container">
        <div class="div-member">
            <img class="icon-banner" src="../assets/members/1.png" alt="">
            <h4>Pedro alvares</h4>
            <p>+51 985 345 158</p>
        </div>
        <div class="div-member">
            <img class="icon-banner" src="../assets/members/2.png" alt="">
            <h4>Pedro alvares</h4>
            <p>+51 985 345 158</p>
        </div>
        <div class="div-member">
            <img class="icon-banner" src="../assets/members/1.png" alt="">
            <h4>Pedro alvares</h4>
            <p>+51 985 345 158</p>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .members-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        background-color: transparent;
        margin: 45px 10px;
        text-align: center;
    }
    .div-member img{        
        border-radius: 50%;
        width: 150px;
        height: 150px;
        object-fit: cover;
    }
</style>