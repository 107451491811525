<template>
<div class="swiper-body">
  <div class="swiper-container">
    <!-- Additional required wrapper -->
    <Swiper 
      :effect="'coverflow'"
      grabCursor: true
      :centeredSlides="true"
      slidesPerView= "'auto'"
      :EffectCoverflow="{
          rotate: 180,
          stretch: 0,
          depth: 900,
          modifier: 1,
          slideShadows: true
    }"
      class="swiper-wrapper"
      grabCursor
      :slides-per-view="'auto'"
      :space-between="30"
    
      :pagination="{ clickable: true }"
      
    >
      <!-- Slides -->
      <SwiperSlide v-slot="{ isActive }" class="swiper-slide">
        <div class="picture">
          <img src="../assets/projects/1.png" alt="">
        </div>
        <div class="detail">
          <h3 >Maras</h3>
          <p v-show="isActive">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero consequatur labore dolores beatae aliquid  consequatur </p>
          <router-link :to="{name:'Terrain'}" v-show="isActive">Ver Mas </router-link> 
        </div>
      </SwiperSlide>
      <SwiperSlide v-slot="{ isActive }" class="swiper-slide">
        <div class="picture">
          <img src="../assets/projects/2.png" alt="">
        </div>
        
        <div class="detail">
          <h3>Simataucca</h3>
          <p v-show="isActive">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero consequatur labore dolores beatae aliquid  consequatur </p>
          <router-link :to="{name:'Terrain'}" v-show="isActive">Ver Mas</router-link> 
        </div>
      </SwiperSlide>
      <SwiperSlide v-slot="{ isActive }" class="swiper-slide">
        <div class="picture">
          <img src="../assets/projects/3.png" alt="">
        </div>
        <div class="detail">
          <h3>Ayarmaca</h3>
          <p v-show="isActive">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero consequatur labore dolores beatae aliquid  consequatur </p>
          <router-link :to="{name:'Terrain'}" v-show="isActive">Ver Mas</router-link> 
        </div>
      </SwiperSlide>
      <SwiperSlide v-slot="{ isActive }" class="swiper-slide">
        <div class="picture">
          <img src="../assets/projects/4.png" alt="">
        </div>
        <div class="detail">
          <h3>Pillcopata</h3>
          <p v-show="isActive">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero consequatur labore dolores beatae aliquid  consequatur </p>
          <router-link :to="{name:'Terrain'}" v-show="isActive">Ver Mas</router-link> 
        </div>
      </SwiperSlide>
      <!--<SwiperSlide v-slot="{ isActive }" class="swiper-slide">
        <div class="picture">
          <img src="../assets/1.jpg" alt="">
        </div>
        <div v-show="isActive" class="detail">
          <h3>Sean Adams</h3>
          <span>Web Designer</span>
        </div>
      </SwiperSlide>
      <SwiperSlide v-slot="{ isActive }" class="swiper-slide">
        <div class="picture">
          <img src="../assets/2.jpg" alt="">
        </div>
        <div v-show="isActive" class="detail">
          <h3>Crystal Davidson</h3>
          <span>Web Designer</span>
        </div>
      </SwiperSlide> -->
    </Swiper>
    <!-- If we need pagination -->
    

  </div>
</div>
    <!-- Slider main container -->

</template>
<script>
    import SwiperCore, { EffectCoverflow,Navigation, Pagination, Scrollbar } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    

    import "swiper/swiper.min.css";
    import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
    import 'swiper/components/pagination/pagination.min.css';


SwiperCore.use([EffectCoverflow,Pagination]);

    //import 'swiper/css';
  export default {
      
    name:'ProjectsListComponent',
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log('hola'+swiper);
        };
        const onSlideChange = (e) => {
            console.log('slide change',e);
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Scrollbar],
        };
      }
  };
</script>
<style scoped>
.swiper-body {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: #000;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 320px;
  background-color: rgb(153, 153, 153);
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.picture {
  width: 320px;
  height: 420px;
  overflow: hidden;
}
.picture img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
.detail {
  position: absolute;
   left: 50%;
    top: 55%;
    transform: translate(-50%,-50%);
  bottom: 0;
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  font-weight: 600;
  text-align: center;
  color: white;
  width: 250px;
  transition: 0.3s all;
}
.detail h3 {
    margin: 10px 0;
    font-size: 28px;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px black;
}
.detail p {
    display: block;
    font-size: 14px;
    font-weight: normal;
    text-align: justify;
    height: auto;
    
}
.detail a{
  margin: 10px 0;
  background: white;
  color: #000;
  border-radius: 10px;
  padding: 5px 15px;
  text-decoration: none;
}
</style>
