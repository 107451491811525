<template>
    <div>
       <div class="container-dismus">
           <!--<button @click="shuffle_1">Shuffle</button>-->
             <transition-group name="flip-list" tag="div" class="container-terrain-carousel">
                <div v-for="(item,index) in items" :key="item.id" class="item-terrain-carousel">
                    <div @click="array_select(index)" :class="index === 1 ?'active-terrain-carousel':''">
                        <h3 class="z-index-9">{{ item.title }}{{index}}</h3>
                        <img :src="getImgUrl(item.img_name)" :alt="item.img_name">
                    </div>
                </div>
            </transition-group>
            <terrain-content-component/>
            <developer-component/>
             <schedule-component
                title="Agendemos un reunión"
                description="No sólo hacer la compra de un terreno, si no también ofreciendo la oportunidad de desarrollar su inversión hasta el punto de ver su casa / bungallow / cabaña hecha realidad y edificada directamente con nosotros."
                btn_name="Contactar por whatsapp"
                link="https://wa.link/n7gr8q"
                :bg="false"
                />
       </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import TerrainContentComponent from './TerrainContentComponent.vue'
    import DeveloperComponent from './DeveloperComponent.vue'
    import ScheduleComponent from '../ScheduleComponent.vue'

    export default {
        components:{
            TerrainContentComponent,
            DeveloperComponent,
            ScheduleComponent,
        },
        data() {
            return {
            
            items: [
                {
                    id:1,
                    title:'Maras',
                    img_name:'slider1',
                },
                {
                    id:2,
                    title:'Simataucca',
                    img_name:'slider',
                },
                {
                    id:3,
                    title:'Ayarmarca',
                    img_name:'slider1',
                },
                {
                    id:4,
                    title:'Other',
                    img_name:'slider1',
                },
                ],
            img_1:'slider',
            }
        },
        methods: {
            array_select(index){
                console.log(index)
                //console.log(this.items.splice(index,1))
                var array_salvar;
                var recoredor = 0;
                var array_igual = this.items[index];

                console.log("array_igual "+JSON.stringify(array_igual)+'total array'+this.items.length);

                if (index == 0){
                    array_salvar = this.items.splice(index,1)
                    this.items = this.items.reverse()
                    this.items.splice(1,0,array_igual)//poner al segundo puedeo este array
                }
                else{
                    while (recoredor < index-1 ){

                        let last = {};

                        console.log('recoredor'+recoredor)
                        last = this.items.shift();
                        console.log('primero sacado '+JSON.stringify(last));
                        this.items.splice(this.items.length,0,last)

                        recoredor++
                        console.log('recoredor'+recoredor+'---------------')
                        console.log('nuevo array'+JSON.stringify(this.items))

                        // if(JSON.stringify(array_igual) === JSON.stringify(this.items[recoredor] && recoredor == 2)){
                        //     console.log('el index es igual  al index'+JSON.stringify(array_igual),JSON.stringify(this.items[recoredor]))
                        //     break;
                        // }
                        
                    }
                }
                



             for(let i=0; i<=this.items.length-1; i++){
                    //console.log(this.items[i]);
                    if(i == index){
                        array_salvar = this.items[i]
                        //this.items.splice(index,1)
                    }
                }
                console.log(array_salvar);
                //this.items.splice(1,0,array_salvar)//poner en  la posicion 2

                /**encontrar un item  */
                //this.items.find(element1 => element1 === index);

            },
            getImgUrl(img_names) {
                var images = require.context('../../assets/', false, /\.jpg$/)
                return images('./' + img_names + ".jpg")
            },
        
            shuffle_1() {
                //this.items = _.shuffle(this.items)
                let array = _.shuffle(this.items)
                console.log('incio'+this.items+'-------'+this.items.slice(1, 3)+'---'+array);
                this.items = array
                //this.others = _.dropRight(this.others)
                //console.log(this.others)
            }
        }
    }
</script>

<style scoped>
    .flip-list-move {
        transition: transform 1s ease;
    }
    .container-terrain-carousel{
        margin: 75px 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 75px;
        justify-content: center;
        text-align: center;
    }
    .container-terrain-carousel>div{
        background-color: cadetblue;
    }
    .active-terrain-carousel{
        scale: 1.2;
    }
    .item-terrain-carousel{
        position: relative;
        height: 250px;
        border-radius: 15px;
    }
    .item-terrain-carousel img{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top:0;
        z-index: 1;
        object-fit: cover;
        border-radius: 15px;
        filter: brightness(0.8);
    }
    .item-terrain-carousel>div{
        width: 100%;
        z-index: 2;
        position: absolute;
        color: white;
        display: grid;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 100%;
        font-size: 20px;
        text-transform: uppercase;
        
    }
    .z-index-9{
        z-index: 9;
    }
</style>