<template>
   <div class="container-building">
       <div>
           <img class="img-building" src="../../assets/projects/building.png" alt="">
       </div>
        <div>
            <h1>construye tu sueños</h1>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Saepe, adipisci doloribus consequatur nam dicta molestias</p>
            <div class="building-icons">
                <div>
                    <img src="../../assets/projects/icon-home.png" alt="">
                    <h4>arquitectura</h4>
                </div>
                <div>
                    <img src="../../assets/projects/icon-pc.png" alt="">
                    <h4>diseño de interiores</h4>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    export default {
        name:'BuildingComponent'
    }
</script>

<style scoped>
    .container-building{
        margin: 0 150px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .img-building{
        width: 350px;
    }
    .building-icons{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .building-icons>div{
        display: grid;
        grid-template-columns: 0.7fr 1fr;
    }
</style>