<template>
    <div>
        <div>
            <img data-aos="fade-up" class="img-banner-title" alt="Vue logo" src="../assets/slider.png">
        </div>
        <div class="container-title-projects-carousel">
            <h2 class="title-projects">Terrenos</h2>
            <terrain-component data-aos="fade-up" />
        </div>

    </div>
</template>

<script>
import TerrainComponent from '../components/terrain/TerrainComponent.vue'
    export default {
        name:'Projects',
        components:{
            TerrainComponent
        }
    }
</script>

<style scoped>
    .img-banner-title{
        height: 450px;
        object-fit: cover;
        width: 100%;
        object-position: bottom;
    }
    .container-title-projects-carousel{
        
    }
    .title-projects{
        
    }
    .title-projects {
    width: 70%;
    margin: .7em auto;
    overflow: hidden;
    text-align: center;
    font-weight:500;
    color: rgb(0, 0, 0);
    font-size: 35px;
    }
    .title-projects:before, .title-projects:after {
    content: "";
    display: inline-block;
    width: 50%;
    margin: 0 .5em 0 -55%;
    vertical-align: middle;
    border-bottom: 1px solid;
    }
    .title-projects:after {
    margin: 0 -55% 0 .5em;
    }
</style>