<template>
    <div class="container-banner-offer">
        <img  src="../assets/icon-banner/bg.png" alt="">
        <div class="container-banner-offer-icons">
            <div>
                <div class="content-img-banner">
                    <img class="icon-banner" src="../assets/icon-banner/justo.png" alt="">
                </div>
                <div>
                    <span>Precio</span>
                    <h4>Justo</h4>
                </div>
            </div>
             <div>
                <div class="content-img-banner">
                    <img class="icon-banner" src="../assets/icon-banner/by.png" alt="">
                </div>
                <div>
                    <span>Alta</span>
                    <h4>Rentabilidad</h4>
                </div>
            </div>
             <div>
                <div class="content-img-banner">
                    <img class="icon-banner" src="../assets/icon-banner/hands.png" alt="">
                </div>
                <div>
                    <h4>Trato Directo</h4>
                    <span>con el propietario</span>
                </div>
            </div>
             <div>
                <div class="content-img-banner">
                    <img class="icon-banner" src="../assets/icon-banner/home.png" alt="">
                </div>
                <div>
                    <span>Terrenos Debidamente</span>
                    <h4>Saneados</h4>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    .container-banner-offer{
        position:relative;
        height: 300px;
        background-color: rgb(51, 95, 51);
        display: flex;
        justify-content: center;
        align-items:center;
        color: white;
    }
    .container-banner-offer>img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        filter: brightness(0.5);
        position: absolute;
    }
    .container-banner-offer h4{
        font-size: 25px;
        margin: 0;
    }
    .container-banner-offer-icons{
        max-width: 1000px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
        align-items:center;
        align-self: center;
        background-color: transparent;
        height:205px;
        z-index: 99;
    }
    .icon-offer{
        font-size: 25px;
        color: white;
    }
    .icon-banner{
        height: 55px;
    }
    .content-img-banner{
        margin-bottom: 15px;
    }
    @media (max-width: 900px){
        .container-banner-offer{
            height: 350px;
        }
        .container-banner-offer-icons{
            height:305px;
            grid-template-columns: repeat(2,1fr);
            gap: 15px;
            text-align: center;
            align-items: center;
            align-self: center;
        }
    }
</style>