<template>
   <div class="menu-mobile" >
        <div class="header-mobile">
            <div @click="tryThis" class="container-logo-mobile">
                <img  width="250" class="logo" alt="Vue logo" src="../../assets/horizontal-blanco.png">
            </div>
            <div class="container-close-mobile">
                <button @click="tryThis">X</button>
            </div>
        </div>
        <div class="body-mobile">
            <ul>
                <li>
                    <router-link to="/" @click="tryThis">
                        Inicio
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'Terrain'}" @click="tryThis">Terrenos</router-link>
                </li>
                <li>
                    <router-link :to="{name:'Projects'}" @click="tryThis">Proyectos</router-link>
                </li>
                <li>
                    <router-link :to="{name:'Contact'}" @click="tryThis">Contacto</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from "vue";
    
    export default defineComponent({
        name: 'MenuMobileComponent',
        emits: ["enlargeText"],
        methods: {
            tryThis() {
                console.log("trying");
                this.$emit("enlargeText", "someValue");
            },
        },
    })
</script>

<style scoped>
    .menu-mobile{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 9999999;
        height: 100vh;
        width: 100%;
        background-color: #415B37F0;
        color:white;
    }
    .header-mobile{
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 25px;
        border-bottom: 1px solid white;
        
    }
    .body-mobile{
        height: 100%;
        text-align: left;
    }
    .body-mobile > ul{
        display: grid;
        justify-content: left;
        grid-template-rows: repeat(5, 1fr);
        height: 100%;
    }
    .body-mobile > ul > li{
        margin: 15px;
        list-style: none;
        text-transform: uppercase;
    }
    .body-mobile > ul > li a{
        color: white;
        text-decoration: none;
        border-bottom: 1px solid white;
    }
    .container-logo-mobile{

    }
    .container-close-mobile{
        display: grid;
        justify-content: end;
        align-content: center;
    }
    .container-close-mobile button{
        height: min-content;
    }
</style>