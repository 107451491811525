<template>
    <div class="container-full">
        <div class="container">
            <div class="container-nav" :class="v_scroll < 150?'p-25':'p-0-25'">
                <div>
                    <router-link to="/">
                        <img class="logo" alt="Vue logo" src="../../assets/horizontal-blanco.png">
                    </router-link>
                </div>
                <div class="nav">
                    <ul class="menu">
                        <li class="item">
                            <router-link :to="{name:'Terrain'}">Terrenos</router-link>
                        </li>
                        <li class="item">
                            <router-link :to="{name:'Projects'}">Proyectos</router-link>
                        </li>
                        <li class="item">
                            <router-link :to="{name:'Contact'}">Contacto</router-link>
                        </li>
                        <li class="item">
                            <div class="nav-icons">
                                <a href="https://www.facebook.com/tierrafertil.pe">
                                    <font-awesome-icon :icon="['fab', 'facebook']" />
                                </a>
                                <a href="https://www.instagram.com/tierrafertil.pe/">
                                    <font-awesome-icon :icon="['fab', 'instagram']" />
                                </a>
                                <a href="https://wa.link/n7gr8q">
                                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                                </a>
                            </div>
                        </li>
                        <li @click="change_menu"  class="toggle-menu">
                            <font-awesome-icon :icon="['fas', 'bars']" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
    </div>
      <transition name="fade">
        <MenuMobileComponent @enlargeText="change_menu" v-if="toggle_menu"></MenuMobileComponent>
    </transition>
    
</template>
<script>
import {ref} from 'vue'
import MenuMobileComponent from '../../components/layout/MenuMobile.vue'

export default {
   name: 'HeaderComponent',
   components:{
       MenuMobileComponent,
    },
    setup(){
        const toggle_menu = ref(false);
        function change_menu(){
            toggle_menu.value = !toggle_menu.value;
        }

        return {toggle_menu,change_menu}
    },
   data(){
       return{
           v_scroll:null,
       }
   },
   methods:{
       handleScroll: function (event) {
            this.v_scroll = window.scrollY;
            window.scrollY,event
        },
   },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
<style scoped>

    a.router-link-exact-active {
        color: #42b983;
        font-weight: bold;
    }
    .container{
        position: relative;
    }
    .toggle-menu{
        display: none;
    }
    .container-full{
        width: 100%;
    }
    .container-nav{
        width: 100%;
        position: fixed;
        display: grid;
        justify-content:space-between;
        grid-template-columns: 200px 1fr;
        background-color: rgba(0, 0, 0, 0.075);
        align-items: center;
        z-index: 99999;
        transition: 0.3s all;
    }
    .container-nav::before{
        content: '';
        width: 95%;
        position: absolute;
        border-bottom: 1px solid white;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }
    .p-25{
        padding: 25px;
    }
    .p-0-25{
        padding: 0 25px;
        background-color: rgba(0, 0, 0, 0.397);
    }
    .logo{
        width: 200px;
    }
    .nav{
        display: grid;
        justify-content: end;
    }
    .menu{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 5px;
        padding: 0;
        margin: 0;
        
    }
    .menu li{
        padding: 0 5px;
        list-style: none;
    }
      .menu li a{
        color: white;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;
    }
    .menu li:hover{
        /* background-color: cornflowerblue; */
        scale: 1.1;
    }
    .nav-icons{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        color: white;
    }
    .nav-icons svg:hover{
        scale: 1.2;
    }
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }

@media (max-width: 900px) {
  .container-nav{
      

  }
   .toggle-menu{
        display: block;
    }
    .menu{
        grid-template-columns: repeat(1,1fr);
    }
    .menu .item{
        display: none;
    }
}

    
</style>