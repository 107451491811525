<template>
    <div class="title-container">
        <h2>PROYECTOS EN CARTERA</h2>
        <p class="description-title">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. At nihil modi architecto earum delectus dignissimos voluptate, 
            facere commodi culpa. Ducimus, accusamus quisquam quia vitae minima obcaecati dolorum error unde! Necessitatibus.
        </p>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .title-container{
        display: grid;
        justify-content: center;
        margin: 35px 0 0 0;
        text-align: center;
    }
    .title-container h2{
        font-size: 25px;
        color: #415B37;
    }
    .description-title{
        width: 700px;
    }
    @media (max-width: 900px){
        .description-title{
            width: 400px;
        }   
    }
</style>