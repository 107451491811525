<template>
    <div>
        <div>
            <img data-aos="fade-up" class="img-banner-title" alt="Vue logo" src="../assets/projects/banner.png">
        </div>
        <div>
            <building-component data-aos="fade-up" />
            <h2 class="decorated"><span>EXPLORA ALGUNOS <br> DE NUESTROS PROYECTOS</span></h2>
            <arqui-component data-aos="fade-up" />
            <schedule-component
                title="Agendemos un reunión"
                description="No sólo hacer la compra de un terreno, si no también ofreciendo la oportunidad de desarrollar su inversión hasta el punto de ver su casa / bungallow / cabaña hecha realidad y edificada directamente con nosotros."
                btn_name="Contactar por whatsapp"
                link="https://wa.link/n7gr8q"
                :bg="false"
                />
        </div>
    </div>
</template>

<script>
import BuildingComponent from '../components/projects/Buildingcomponent.vue'
import ArquiComponent from '../components/projects/ArquiComponent.vue'
import ScheduleComponent from '../components/ScheduleComponent.vue'
    export default {
        name:'Projects',
        components:{
            BuildingComponent,
            ArquiComponent,
            ScheduleComponent,
        }
    }
</script>

<style scoped>
    .img-banner-title{
        height: 450px;
        object-fit: cover;
        width: 100%;
        object-position: bottom;
    }

    .decorated{
        overflow: hidden;
        text-align: center;
        color: #415B37;
    }
    .decorated > span{
        position: relative;
        display: inline-block;
    }
    .decorated > span:before, .decorated > span:after{
        content: '';
        position: absolute;
        top: 50%;
        border-bottom: 2px solid;
        width: 591px;
        margin: 0 20px;
    }
    .decorated > span:before{
        right: 100%;
    }
    .decorated > span:after{
        left: 100%;
    }
</style>