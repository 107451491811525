<template>
  <div id="nav">
    <header-component/>
  </div>
    <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
  <footer-component/>
</template>
<script>
import HeaderComponent from './components/layout/HeaderComponent.vue'
import FooterComponent from './components/layout/FooterComponent.vue'
export default {
  components:{
     HeaderComponent,
     FooterComponent
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,body{
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
*{
  box-sizing: border-box;
}
p{
  line-height: 25px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>
