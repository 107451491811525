<template>
   <div class="manager">
        <div class="container-manager">
        <div class="content-manager">
            <h3>Somos tus compañeros</h3>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus tempora ea dolores quod. Possimus unde explicabo natus dolorum ipsum assumenda, officiis quisquam</p>
            <div class="icon-manager">
                <div>
                    <img class="icon-banner" src="../assets/icon-manager/justo.png" alt="">
                    <span>precio</span>
                    <span class="title-manager-icon">Justo</span>
                </div>
                <div>
                    <img class="icon-banner" src="../assets/icon-manager/by.png" alt="">
                    <span>alta</span>
                    <span class="title-manager-icon">Rentabilidad</span>
                </div>
                <div>
                    <img class="icon-banner" src="../assets/icon-manager/hands.png" alt="">
                    <span class="title-manager-icon">Trato directo</span>
                    <span>con el propietario</span>
                </div>
            </div>
        </div>
        <div class="img-manager">
            <img src="../assets/andre.png" alt="">
        </div>
    </div>
   </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
 .manager{
     display: grid;
     justify-content: center;
     align-items: center;
     margin: 150px 0;
 }
 .container-manager{
     width: 850px;
     display: grid;
     grid-template-columns:1fr 300px;
 }
 .content-manager{
     padding: 25px;
     display: grid;
 }
 .content-manager h3{
     text-align: right;
     margin: 0 1rem;
     font-size: 25px;
     text-transform: uppercase;
     color: #415B37;
 }
 .content-manager p{
     text-align: right;
 }
 .img-manager img{
     width:100%;
     height: 400px;
     border-radius: 10px;
     object-fit: cover;
 }
 .icon-manager{
     display: grid;
     grid-template-columns: repeat(3, 1fr);
 }
 .icon-manager div{
     display: grid;
     justify-content: center;
     text-align: center;
     align-self: center;
 }
 .icon-manager div img{
     margin: auto;
 }
 .title-manager-icon{
     font-size: 18px;
     font-weight: bold;
 }
  @media (max-width: 900px){
        .container-manager{
            grid-template-columns: 1fr;
            height: auto;
            width: 100%;
        }
    }
</style>