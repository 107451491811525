<template>
    <div class="investment-container">
        <div class="investment-content">
            <div class="investment-row">
                <h4>Desarrolla tu inversion</h4>
                <p>Contamos con un equipo de profesionales que te ayudara a materializar tus suenos</p>
                 <router-link :to="{name:'Contact'}">
                        Contactanos
                    </router-link>
            </div>
            <div class="img-investment">
                <img alt="Vue logo" src="../assets/investment.png">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    .investment-container{
        display: grid;
        margin: auto;
        justify-content: center;
    }
    .investment-content{
        border-radius: 10px;
        width: 900px;
        display: grid;
        grid-template-columns: 300px 1fr;
        
        box-shadow: 2px 2px 10px rgb(122, 122, 122);
        
    }
    .img-investment{
        display: block;
    }
    .img-investment img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0px 10px 10px 0px;
    }
    .investment-row{
        padding: 15px;
        text-align: right;
    
        align-self: center;
    }
    .investment-row h4{
        font-size: 25px;
    }
    .investment-row a{
        padding: 5px 10px;
        border-radius: 15px;
        background-color: #000;
        color: #fff;
        border: none;
        width: max-content;
        text-decoration: none;
    }
    @media (max-width: 900px){
        .investment-content{
            grid-template-columns: 1fr;
            width: auto;
            margin: 15px;
        }
    }
</style>