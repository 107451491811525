<template>
    <div class="container-developer">
        <img class="img-developer" src="../../assets/terrain/banner-developer.png" alt="">
       <div class="content-developer">
            <div class="developer">
                <h2>DESARROLLA TU INVERSIÓN</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima recusandae aut quasi sunt autem quibusdam nulla</p>
                <button>Ver mas</button>
            </div>
           <div class="d-relative">
                <div class="float-developer">
                    <img src="../../assets/terrain/float.png" alt="">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem quas sequi distinctio ex rem consequatur. Odi nctio ex rem consequatur. Odit</p>
                </div>
           </div>
       </div>
    </div>
</template>
<script>
    export default {
        
    }
</script>
<style scoped>
    .container-developer{
        position: relative;
        height: 400px;
        background-color: blue;
        margin: 85px 0;
    }
    .img-developer{
        position: absolute;
        width:100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        filter: brightness(0.5);
    }
    .content-developer{
        width: 900px;
        z-index: 2;
        position: absolute;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        height: 100%;
    
    }
    .developer{
        display: grid;
        align-self: center;
        color: white;
    }
    .developer button{
        width: max-content;
    }
    .d-relative{
        position: relative;
    }
    .float-developer{
        margin-top:-25px;
        position: absolute;
        width: 350px;
        height:450px;
        border: none;
        border-radius: 10px;
        background-color: white;
        box-shadow: 2px 2px 10px black;    }
    .float-developer img{
        width: 100%;
        border-radius: 10px 10px 0 0;
    }
    .float-developer p{
        padding: 15px 35px;
        text-align: justify;
    }
</style>