<template>
    <div class="container-schedule">
        <img v-if="bg" class="img-schedule" src="../assets/bg.png" alt="">
        <div class="content-schedule">
        <h3 :class="bg?'white':'dark'" class="title-schedule">{{ title }}</h3>
        <p :class="bg?'white':'black'">{{ description }}</p>
        <a target="_blank" :href="link" :class="bg?'dark bg-white':'white bg-dark'" class="btn-schedule" :to="link">{{btn_name}}</a>
    </div>
        </div>
</template>
<script>
    export default {
        name:'ScheduleComponent',
        props:['title','description','btn_name','link','bg']
    }
</script>

<style scoped>
    .img-schedule{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        filter: brightness(0.5);
    }
    .container-schedule{
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
        height: 350px;
        color: white;
        text-align: center;
    }
    .content-schedule{
        width: 700px;
        z-index: 1;
    }
    .dark{
        color: #415b37;
    }
    .black{
        color: black;
    }
    .white{
        color:white;
    }
    .bg-dark{
        background-color: #415b37 ;
    }
    .bg-white{
        background-color: white;
    }
    .btn-schedule{
        padding: 10px;
        border: none;
        border-radius: 15px;
        
        text-decoration: none;
        
    }
    .btn-schedule:hover{
        background-color: rgb(135, 222, 135);
    }
    .title-schedule{
        font-size: 35px;
        text-transform: uppercase;
    }
     @media (max-width: 900px){
        .content-schedule{
            width: 500px;
        }
        .title-schedule{
            font-size: 25px;
        }
     }
</style>