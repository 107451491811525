<template>
    
    <div class="container-comment">
        <div>
        <h2>
            Nuestros clientes dicen
        </h2>
    </div>
         <swiper :slidesPerView="1" :spaceBetween="10"
          :pagination='{
            "clickable": true
            }'
            :breakpoints='{
                "640": {
                    "slidesPerView": 2,
                    "spaceBetween": 10
                },
                "768": {
                    "slidesPerView": 3,
                    "spaceBetween": 10
                },
                "1024": {
                    "slidesPerView": 3,
                    "spaceBetween": 10
                }
            }' class="mySwiper">
                <swiper-slide>
                    <div class="comment">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci ducimus necessitatibus temporibus voluptas,</p>
                        <div class="container-icons">
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                        </div>
                        <div class="content-member-comment">
                            <img class="img-comment" width="50px" src="../assets/members/1.png" alt="">
                            <div>
                                <h6>Roger Wagner</h6>
                                <p>Inversionista</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="comment">
                        <p>"Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci ducimus necessitatibus temporibus voluptas ibus temporibus voluptas"</p>
                        <div class="container-icons">
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                        </div>
                        <div class="content-member-comment">
                            <img class="img-comment" width="50px" src="../assets/members/2.png" alt="">
                            <div>
                                <h6>Roger Wagner</h6>
                                <p>Inversionista</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="comment">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci ducimus necessitatibus temporibus voluptas,</p>
                        <div class="container-icons">
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                        </div>
                        <div class="content-member-comment">
                            <img class="img-comment" width="50px" src="../assets/members/1.png" alt="">
                            <div>
                                <h6>Roger Wagner</h6>
                                <p>Inversionista</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="comment">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci ducimus necessitatibus temporibus voluptas,</p>
                        <div class="container-icons">
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                        </div>
                        <div class="content-member-comment">
                            <img class="img-comment" width="50px" src="../assets/members/2.png" alt="">
                            <div>
                                <h6>Roger Wagner</h6>
                                <p>Inversionista</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                 <swiper-slide>
                    <div class="comment">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci ducimus necessitatibus temporibus voluptas,</p>
                        <div class="container-icons">
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                        </div>
                        <div class="content-member-comment">
                            <img class="img-comment" width="50px" src="../assets/members/1.png" alt="">
                            <div>
                                <h6>Roger Wagner</h6>
                                <p>Inversionista</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
             
              <swiper-slide>
                    <div class="comment">
                        <p>"Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci ducimus necessitatibus temporibus voluptas"</p>
                        <div class="container-icons">
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                            <font-awesome-icon :icon="['fas', 'star']"/>
                        </div>
                        <div class="content-member-comment">
                            <img class="img-comment" width="50px" src="../assets/members/2.png" alt="">
                            <div>
                                <h6>Roger Wagner</h6>
                                <p>Inversionista</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
             
            </swiper>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/components/pagination/pagination.min.css';
    import 'swiper/components/navigation/navigation.min.css';


    export default {
         components: {
            Swiper,
            SwiperSlide,
        },
    }
</script>

<style scoped>
.container-comment{
    max-width: 1000px;
    margin: auto;
    margin-bottom: 90px;
}
.container-comment h2{
    color: #415B37;
    text-transform: uppercase;
    text-align: center;
    font-size: 25px;
    margin: 3rem 0;
}
.comment{
    display: grid;
    text-align: start;
    box-shadow: 1px 1px 15px #b8b8b8;
    padding: 15px ;
    border-radius: 15px;
    
}
.content-slider{
    position: absolute;
    padding: 25px;
    width: 400px;
    display: grid;
    justify-content: left;
    background-color: transparent;
    text-align: start;
    color: white;
}
.content-slider h2{
    font-size: 45px;
}
.content-slider button{
    color: black;
    padding: 5px 15px;
    background-color: white;
    border-radius: 15px;
    width: max-content;
    border:none;
    font-size: 15px;
}
.slider-fertil{
    width: 100%;
    height: 650px;
}
.swiper {
  width: 100%;
  height: 100%;
}
.mySwiper{
    height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 350px;
  
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 10px;
}
.img-comment{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}
.content-member-comment{
    display: grid;
    grid-template-columns: 50px 1fr;
    justify-content: end;
    align-items: center;
    text-align: start;
    padding: 0 10px;
}
.content-member-comment div{
    padding: 0 15px;
}
.content-member-comment div h6{
    margin: 0;
}
.content-member-comment p{
    padding: 0;
    margin: 0;
}
.container-icons svg{
    font-size: 10px;
    margin: 5px;
}
</style>