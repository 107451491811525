<template>
   <div class="container-content-terrain">
        <div class="content-terrain">
            <div>
                <h2>Area Total</h2>
                <p>192.15 m2</p>
                <h2>Ubicación</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur maiores delectus voluptatum animi</p>
                <h2>Descripción</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur maiores delectus voluptatum animi
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur maiores delectus voluptatum animi
                </p>
                <h2>Financiamineto</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur maiores delectus voluptatum animi</p>
            </div>
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4749.706375961729!2d-72.05910980317134!3d-13.455018492517707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7f89cd27255c46e9!2sTierra%20f%C3%A9rtil%20-%20Bienes%20Ra%C3%ADces%20Cusco!5e0!3m2!1ses-419!2spe!4v1632327182334!5m2!1ses-419!2spe" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        </div>
        <div class="container-g">
            <h2>Con todo lo que necesitas</h2>
            <div class="services-container">
                <div class="service">
                    <img class="img-banner-title" alt="Vue logo" src="../../assets/icon-services/view.png">
                    <div>
                        <span>Vista a las montañas</span>
                        <h3>Y BOSQUES</h3>
                    </div>
                </div>
                <div class="service">
                    <img class="img-banner-title" alt="Vue logo" src="../../assets/icon-services/basic.png">
                    <div>
                        <span>acceso a</span>
                        <h3>SERVICIOS BÁSICOS</h3>
                    </div>
                </div>
                <div class="service">
                    <img class="img-banner-title" alt="Vue logo" src="../../assets/icon-services/car.png">
                    <div>
                        <span>acceso</span>
                        <h3>VEHICULAR</h3>
                    </div>
                </div>
                <div class="service">
                    <img class="img-banner-title" alt="Vue logo" src="../../assets/icon-services/layout.png">
                    <div>
                        <span>lotes proyectados</span>
                        <h3>TRES</h3>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    export default {
        data(){
            
        }
    }
</script>

<style scoped>
    .container-content-terrain{
        display: grid;
        justify-content: center;
    }
    .content-terrain{
        width: 1000px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
    .content-terrain h2{
        margin: 0;

    }
    .container-g{
         width: 1000px;
    }
    .services-container{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    .service{
        display: grid;
        grid-template-columns:0.2fr 1fr;
        gap: 15px;
        align-content: center;
        align-items: center;
    }
    .service img{
        width: 100%;
    }
    .service span{
        font-size: 12px;
        color: rgb(73, 73, 73);
        text-transform: uppercase;
    }
    .service h3{
        margin: 0;
        font-size: 15px;
    }
</style>