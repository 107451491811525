<template>
    <div class="container-financing">
        <div class="div-img-financing">
            <img src="../assets/financing.png" alt="">
        </div>
        <div class="content-financing">
            <h2>Facilidades de pago</h2>
            <h4>Financiamiento con CajaCusco</h4>
            <p>totam quasi nihil ullam quam exercitationem velit eveniet distinctio ea repudiandae expedita porro!</p>
            <h4>Hasta x anos Para pagarlo</h4>
            <p>totam quasi nihil ullam quam exercitationem velit eveniet  ea repudiandae expedita porro!</p>
            <h4>No cuota inicial</h4>
            <p>totam quasi nihil ullam quam exercitationem velit eveniet  repudiandae expedita porro!</p>
        </div>
    </div>
</template>

<script>
    export default {
        name:'FinancingboxComponent',
        
    }
</script>

<style scoped>
    .container-financing{
        margin: 45px 0;
        width: 100%;
        background-color: rgb(255, 255, 255);
        height: 450px;
        display: grid;
        grid-template-columns: 450px 1fr;
        text-align: left;
    }
    .content-financing{
        display: grid;
        justify-content: center;
        padding: 55px;
    }
    .content-financing h3{
        font-size: 2.5rem;
        margin: 15px 0;
    }
    .content-financing h4{
        

    }
    .content-financing p{
        margin: 0;
        
    }
    .div-img-financing{
        background-color: rgb(255, 255, 255);
    }
    .div-img-financing img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: 900px){
        .container-financing{
            grid-template-columns: 1fr;
            height: auto;
        }
    }
</style>