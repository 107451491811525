<template>
    <div class="container-arqui">
        <img class="main-project" alt="Projectos Tierra fertil" src="../../assets/projects/main-project.png">
        <div class="model-container">
            <div>
                <p>vivienda</p>
                <h2>MODELO A</h2>
            </div>
            <div>
                <p>vivienda</p>
                <h2>MODELO B</h2>
            </div>
            <div>
                <p>vivienda</p>
                <h2>MODELO C</h2>
            </div>
        </div>
        <div class="img-model-container">
            <div>
                <img class="main-project" alt="Vue logo" src="../../assets/projects/model1.png">
            </div>
            <div>
                <img class="main-project" alt="Vue logo" src="../../assets/projects/model2.png">
            </div>
            <div>
                <img class="main-project" alt="Vue logo" src="../../assets/projects/model3.png">
            </div>
        </div>
        <div class="content-description">
            vivienda
            <h3>MODELO A</h3>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facilis, culpa. Impedit blanditiis animi labore modi obcaecati </p>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .container-arqui{
        position: relative;
        margin: 80px 250px;
        
    }
    .container-img{
        
    }
    .main-project{
      width: 100%;
        
    }
    .model-container{
        margin-top: -30px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        width: 750px;
        margin-left: auto;
        margin-right: auto;
        gap: 5px;
    }
    .model-container>div{
        height: 60px;
        /* border-left:1px solid black  ; */
        background-color: rgb(207, 207, 207);
        box-shadow: 2px 2px 5px black;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .model-container h2 {
        margin:-20px 0 0 0 ;
        font-size: 20px;
        
    }
    .model-container p{
        margin: 0;
    }
    .model-container>div:nth-child(2){
        scale: 1.1;
    }
    .img-model-container{
        /* background: chartreuse; */
        right: 0;
        left: 0;
        width: 750px;
        margin: 0 auto;
        position: absolute;
        top: 120px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
        justify-content: center;
    }
    .content-description{
        position: absolute;
        top: 335px;
        width: 550px;
        right: 0;
        left: 0;
        color: white;
        margin: 0 auto;
    }   
</style>