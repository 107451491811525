<template>
    <div>
        <img class="banner-header" alt="Projectos Tierra fertil" src="../assets/projects/banner.png">
        <div class="container-form">
            <h1>AGENDEMOS UNA REUNIÓN</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga facilis nostrum deserunt cupiditate vitae recusandae reiciendis consequuntur tempora quaerat fugiat in saepe voluptates, voluptas doloremque vero dolorem numquam. Consequuntur, assumenda.</p>
            <div class="content-form">
                <div class="box divide-two">
                    <div class="box-input"> 
                        <label for="name">NOMBRE *</label>
                        <input id="name" type="text">
                    </div>
                    <div class="box-input">
                        <label for="business">EMPRESA</label>
                        <input type="text">
                    </div>
                </div>
                <div class="box divide-two">
                    <div class="box-input">
                        <label for="">ASUNTO *</label>
                        <input type="text">
                    </div>
                    <div class="box-input">
                        <label for="phone">TELEFONO/CELULAR *</label>
                        <input id="phone" type="text">
                    </div>
                </div>
                <div class="box box-textarea">
                    <label for="menssage">MENSAJE*</label>
                    <textarea name="menssage" id="" cols="30" rows="10"></textarea>    
                </div>
                <div class="btn-container">
                    <button>
                        ENVIAR
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .container-form{
        width: 700px;
        margin: 50px auto;
    }
    .banner-header{
        height: 250px;
        width: 100%;
        object-fit: cover;
        object-position: bottom;
    }
    .divide-two{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
    .box{
        margin: 20px 0;
    }
    .box-input label{
        widows: 100%;
    }
    .box-input input{
        width: 100%;
    }
    .box-textarea textarea{
        width: 100%;
    }
    label{
        font-size: 14px;
    }
    input{
        padding: 7px;
    }
    .btn-container{
        display: grid;
        justify-content: center;
    }
    .btn-container button{
        background: #415B37;
        padding: 10px 45px ;
        border-radius: 20px;
        color: white;
        border: none;
        
    }
</style>