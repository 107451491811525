<template>
   <div>
      <div>
        <slider-component/>
        <we-offer-component data-aos="fade-up" />
        <banner-offer-component data-aos="fade-up"/>
        <title-component data-aos="fade-up"/>
        <projects-list-component data-aos="fade-up"/>
        <investment-component data-aos="fade-up"/>
        <financingbox-component data-aos="fade-up"/>
        <manager-component data-aos="fade-up"/>
        <members-component data-aos="fade-up"/>
        <coment-component data-aos="fade-up"/>
        <schedule-component  data-aos="fade-up"
        title="Agendemos un reunión"
        description="No sólo hacer la compra de un terreno, si no también ofreciendo la oportunidad de desarrollar su inversión hasta el punto de ver su casa / bungallow / cabaña hecha realidad y edificada directamente con nosotros."
        btn_name="Contactar por whatsapp"
        link="https://wa.link/n7gr8q"
        :bg="true"
        />
      </div>
   </div>
</template>
<script>
import SliderComponent from '@/components/SliderComponent.vue'
import WeOfferComponent from '@/components/WeOfferComponent.vue'
import BannerOfferComponent from '../components/BannerOfferComponent.vue'
import TitleComponent from '../components/TitleComponent.vue'
import ProjectsListComponent from  '../components/ProjectsListComponent.vue'
import InvestmentComponent from '../components/InvestmentComponent.vue'
import FinancingboxComponent from '../components/FinancingboxComponent.vue'
import ManagerComponent from '../components/ManagerComponent.vue'
import MembersComponent from '../components/MembersComponent.vue'
import ComentComponent from '../components/ComentComponent.vue'
import ScheduleComponent from '../components/ScheduleComponent.vue'



export default {
    name:'Started',
    components: {
    'slider-component':SliderComponent,
    'we-offer-component':WeOfferComponent,
    'banner-offer-component':BannerOfferComponent,
    TitleComponent,
    ProjectsListComponent,
    InvestmentComponent,
    FinancingboxComponent,
    ManagerComponent,
    MembersComponent,
    ComentComponent,
    ScheduleComponent,


    
  }
}
</script>
<style scoped>


</style>