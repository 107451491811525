<template>
    <div class="container-footer">
        <img class="img-footer" alt="Vue logo" src="../../assets/simbolo.svg">
        <div class="content-footer">
            <div class="icons-container-footer">
                <font-awesome-icon :icon="['fab', 'facebook']"/>
                <font-awesome-icon :icon="['fab', 'instagram']"/>
                <font-awesome-icon :icon="['fab', 'youtube']"/>
            </div>
            <p>Recibe nuestra informacion por e/mail</p>
            <div class="container-input">
                <input placeholder="email" type="text">
                <button class="btn-footer-input">
                    <font-awesome-icon :icon="['fas', 'angle-right']"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .img-footer{
        position: absolute;
        left: 0;
        z-index: 1;
        height: 100%;
    }
    .container-footer{
         position: absolute; 
        background-color: #415B37;
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
        height: 250px;
        color: white;
    }
    .content-footer{
        width: 700px;
        z-index: 1;
        position: absolute;
          left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
    }
    .container-input{
        height: 35px;
        
        
    }
    .container-input input{
        height: 100%;
        width: 90%;
        border: 1px solid white;
        background-color: transparent;
        padding: 10px;
        font-weight: bold;
        color: white;
    }
    .container-input button{
        padding: 10px;
    }
    .icons-container-footer svg{
        margin: 5px;
        font-size: 35px;
    }
    .btn-footer-input{
        border: none;
        height: 100%;
    }
    @media (max-width: 900px){
        .content-footer{
            width: 500px;
        }
    }
</style>