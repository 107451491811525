import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

//import 'swiper/swiper-bundle.css';

// inport AOS
import AOS from 'aos'
import 'aos/dist/aos.css'


//font awemsome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
import router from './router'
dom.watch();

const app = createApp(App).use(router)
.component("font-awesome-icon", FontAwesomeIcon)


app.use(AOS.init({
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
}));

app.mount('#app')


